import styles from './SmartParking.module.scss';
// import vanImage from '../../data/images/Vancouver-Pic.jpeg';
import parking from '../../data/images/parking.jpg';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useEffect } from 'react';

function SmartParking() {

    const ns = getTranslationNS('App');
    const { t } = useTranslation([ns, 'common']);

    useEffect(() => window.scrollTo(0, 0), []);

    t;

    return (
        <div className={styles.component}>
            <div className={styles.article}>
                <div className={styles.titleI}>Smart City & Parking</div>
                <div className={styles.contentSec}>
                    <img className={styles.img} src={parking} alt="" />
                    <div className={styles.content}>
                        <p className={styles.para}>Aupera’s technology empowers organizations, managers and your workforce to operate more efficiently with great tools like license plate recognition combined with automated payments, parkade/lot or street parking violations and road marking detection. It’s time to improve operational efficiencies for parking operators and city traffic operations.</p>
                        <p className={styles.para}>Cameras are everywhere in the city. How can city administrators or city infrastructure operators utilize existing cameras to improve city management efficiencies? Aupera’s video AI solution provides license plate recognition which can be combined with automated payments, parking violation detection, road marking detection, and more. Solutions can be deployed on either portable edge platforms or in datacenters.</p>
                    </div>
                </div>
   
                <div className={styles.titleII}>Available AI functions includes:</div>
                <ul className={styles.paraBottom}>
                    <li>License plate recognition.</li>
                    <li>Parking violation.</li>
                    <li>Road marking detection for traffic rule enforcement</li>
                    <li>Mask detection and Crowd statistics for public health management (security, Police, events)</li>
                    <li>Face recognition for special access zone management</li>
                </ul>

            </div>

        </div>
    );
}

export default SmartParking;
