import styles from './SmartBuilding.module.scss';
// import vanImage from '../../data/images/Vancouver-Pic.jpeg';
import building from '../../data/images/building.jpg';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useEffect } from 'react';

function SmartBuilding() {

    const ns = getTranslationNS('App');
    const { t } = useTranslation([ns, 'common']);

    t;

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <div className={styles.component}>
            <div className={styles.article}>
                <div className={styles.titleI}>Smart Building</div>
                <div className={styles.contentSec}>
                    <img className={styles.img} src={building} alt="" />
                    <div className={styles.content}>
                        <p className={styles.para}>Integrate Aupera’s Video management, processing and Edge AI computing as part of a Smart Building solution, helping you to control personnel building access, manage property intrusion alerts, anomaly object detection, building parking & violations, even employee & customer happiness index, and more. On premise deployment addresses privacy and data sovereignty.</p>
                        <p className={styles.para}>Aupera integrates seamlessly to building management platforms or system integrators for an end-to-end solution from connecting and managing all cameras, to real-time edge on-prem video AI analytics. You gain deeper understanding and control of building access, can manage property intrusion alerts, understand anomaly object detection, see building parking violation, even employee & customer happiness index, and more.</p>
                    </div>
                </div>
   
                <div className={styles.titleII}>Available AI functions includes:</div>

                 <ul className={styles.paraBottom}>
                    <li>Face recognition for building access management. Free evaluation license is available here on <a className={styles.redir} href="https://www.xilinx.com/products/acceleration-solutions/aupera-facial-recognition-solution.html" target="_blank"  rel="noreferrer">Xilinx App Store</a>. </li>
                    <li>Mask detection and Crowd statistics for public health management. </li>
                    <li>Virtual fence intrusion alert for building safety application and much more. </li>
                    <li>Parking violation. </li>
                    <li>Anomaly detection for oversized objects.</li>
                    <li>Face emotion detection for staff/consumers satisfaction index.</li>
                </ul>

            </div>

        </div>
    );
}

export default SmartBuilding;
