import styles from './DialogueWindow.module.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export default function DialogueWindow(props) {

    const { onClose, headerText, resizable, help } = props;

    const [trackHeaderMove, setTrackHeaderMove] = useState(false);
    const [style, setStyle] = useState({});
    const [initCursorPos, setInitCursorPos] = useState(false);
    const [initWinPos, setInitWinPos] = useState({});
    const [contentSize, setContentSize] = useState(null);
    const [showHelp, setShowHelp] = useState(false);

    const initWindowMovement = (e) => {
        setTrackHeaderMove(true);
        setInitCursorPos({ x: e.screenX, y: e.screenY });
        setInitWinPos({
            left: e.nativeEvent.target.offsetParent.offsetLeft,
            top: e.nativeEvent.target.offsetParent.offsetTop,
        });
        window.addEventListener('mouseup', stopWindowMovement);
    };

    const mouseMoved = (e) => {
        const diffLeft = initCursorPos.x - e.screenX;
        const diffTop = initCursorPos.y - e.screenY;
        const left = initWinPos.left - diffLeft;
        const top = initWinPos.top - diffTop;
        setStyle({ left, top });
    };

    const stopWindowMovement = () => {
        setTrackHeaderMove(false);
        window.removeEventListener('mouseup', stopWindowMovement);
    };

    // Workaround for keeping size as for initial content, 
    // like ModelZoo before filtering
    // ! Rework later !
    useEffect(() => {
        resizable && setTimeout(() => {
            const contentEl = document.getElementById(`content-${headerText.replace(/ /g, '')}`);
            const { offsetHeight, offsetWidth } = contentEl;
            setContentSize({ h: offsetHeight, w: offsetWidth });
        }, 50);
    }, []);

    return (
        <div
            className={styles.veil}
        >
            <main className={`${styles.dialogue_window} `}
                style={style}
            >
                <header
                    className={styles.header}
                >
                    <h3
                        onMouseDown={(e) => initWindowMovement(e)}
                        onMouseMove={(e) => trackHeaderMove && mouseMoved(e)}
                        className={styles.header__label}
                    >{headerText}</h3>

                    {help &&
                        <section
                            className={styles.help}
                            onClick={() => setShowHelp(!showHelp)}
                        >
                            <span>Help</span>
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className={styles.help__button}
                                onClick={() => console.log('info')}
                                title='Refresh Host Info'
                            />
                        </section>}

                    <div
                        className={styles.header__close}
                        onClick={() => onClose()}
                    >
                        <FontAwesomeIcon
                            //size={'2xl'}
                            icon={faXmark}
                        />

                    </div>
                </header>
                <section
                    className={`${styles.content} ${resizable ? styles.resizable : ''}`}
                    id={`content-${headerText.replace(/ /g, '')}`}
                    style={contentSize ? { width: contentSize.w, height: contentSize.h } : {}}
                >

                    {props.children}
                </section>
                {showHelp &&
                    <section className={styles.help__tips}>
                        {help.map((el, i) =>
                            <article
                                key={`help-entry-${i}`}
                                className={styles.help__tip}
                            >
                                {el.text &&
                                    <p className={styles.help__tip__text}>
                                        {el.text}
                                    </p>}
                                {el.linkText &&
                                    <p
                                        className={styles.help__tip__link}
                                        onClick={() => window.open(el.link, '_blank').focus()}
                                    >
                                        {el.linkText}
                                    </p>
                                }
                            </article>
                        )}
                    </section>}
            </main>
        </div>
    );
}

DialogueWindow.propTypes = {
    onClose: PropTypes.func,
    headerText: PropTypes.string,
    children: PropTypes.any,
    resizable: PropTypes.bool,
    help: PropTypes.array,
};