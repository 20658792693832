import styles from './WorkSafety.module.scss';
import work from '../../data/images/work.jpg';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useEffect } from 'react';

function WorkSafety() {

    const ns = getTranslationNS('App');
    const { t } = useTranslation([ns, 'common']);

    useEffect(() => window.scrollTo(0, 0), []);

    t;

    return (
        <div className={styles.component}>
            <div className={styles.article}>
                <div className={styles.titleI}>Work Safety</div>
                <div className={styles.contentSec}>
                    <img className={styles.img} src={work} alt="" />
                    <div className={styles.content}>
                        <p className={styles.para}>Aupera understands the importance of workplace safety and the associated costs and liabilities. Our technology offers staff on duty detection, personal safety gear monitoring and more efficient HSSE Management for construction, oil & gas, manufacturing, warehouses and more. </p>
                        <p className={styles.para}>Work safety should be the first priority for all working sites. Aupera’s integrated video AI solution can help you monitor staff on duty situation, personal safety gear and more efficient HSSE Management for construction, oil & gas, manufacturing, warehouses and more. </p>
                    </div>
                </div>
   
                <div className={styles.titleII}>Available AI functions includes:</div>
                <ul className={styles.paraBottom}>
                    <li>Safety helmet detection.</li>
                    <li>Safety vest detection.</li>
                    <li>Mask detection and Crowd statistics for public health compliance.</li>
                    <li>Staff on duty alert for guarding posts. </li>
                </ul>

            </div>

        </div>
    );
}

export default WorkSafety;
