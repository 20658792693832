import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translFilesMap from 'translFilesMap.json';

i18n
  .use(Backend)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    defaultNS: 'common',
    ns: Object.values(translFilesMap),
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: true
    }
  });

  export default i18n;