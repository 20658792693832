import styles from './Solutions.module.scss';
// import vanImage from '../../data/images/Vancouver-Pic.jpeg';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import smartBuilding from '../../data/images/building.jpg';
import smartParking from '../../data/images/smart-parking.png';
import smartRetail from '../../data/images/smart-retail.png';
import workSafety from '../../data/images/work-safety.jpg';

function Solutions() {

    const ns = getTranslationNS('App');
    const { t } = useTranslation([ns, 'common']);

    const navigate = useNavigate();

    useEffect(() => window.scrollTo(0, 0), []);

    t;
    
    const solutions = [
        {
            name: 'Smart Retail',
            img: smartRetail,
            header: 'Smart Retail',
            text: 'With Aupera, taking your retail operation to the next level with existing camera technology can help you accelerate in-store or data center real-time smart retail AI applications. Details include loose item recognition, fraud detection, customer demographics, inventory, shelf management, and more to create efficiencies and improve customer experiences.',
            link: '/solutions/smart-retail',
        }, {
            name: 'Smart Parking',
            img: smartParking,
            header: 'Smart Parking',
            text: 'Aupera’s advanced AI algorithm enables high accuracy (99%) License Plate Recognition with regular IP cameras, during the day and night. Applications include Re-ID, parking vacancy and parkade or street parking violations and road marking detection. It’s time to improve operational efficiencies for parking operators and city traffic operations.',
            link: '/solutions/smart-parking',
        }, {
            name: 'Work Safety',
            img: workSafety,
            header: 'Work Safety',
            text: 'Aupera understands the importance of workplace safety and the associated costs and liabilities. Our technology offers staff on duty detection, personal safety gear monitoring and more efficient HSSE Management to understand and delivers the processes for construction, oil & gas, manufacturing, warehouses and more.',
            link: '/solutions/work-safety',
        }, {
            name: 'Smart Building',
            img: smartBuilding,
            header: 'Smart Building',
            text: 'Integrate Aupera’s Video management, processing and Edge AI computing as part of a Smart Building solution, helping you to control personnel building access, manage property intrusion alerts, anomaly object detection, building parking & violations, even employee & customer happiness index, and more.',
            link: '/solutions/smart-building',
        }
    ];

    return (
        <div className={styles.component}>
        <div className={styles.article}>
            <div className={styles.titleI}>Our Solutions</div>
            <p className={styles.para}>Based on VMSS Video AI software framework, Aupera is providing best-in-class AI solutions or platforms for Smart Parking, Smart Building, Smart Retail and Working Safety. <br></br>With our advanced AI algorithm, the License Plate Recognition accuracy achieves 99%, with regular IP cameras, during the day and night, using regular IP cameras in actual customer usage.</p>

        </div>

            
        <article className={styles.solutions}>
            {solutions.map(sol => (
                <div key={sol.name} className={styles.solutions__card}>
                    <img className={styles.solutions__card__img} src={sol.img} alt={sol.name}/>
                    <h2 className={styles.solutions__card__header}>{sol.header}</h2>
                    <p className={styles.solutions__card__text}>{sol.text}</p>
                    <p
                        className={styles.solutions__card__button}
                        onClick={() => navigate(sol.link)}
                    >Find Out More
                    </p>
                </div>
            ))}
        </article>
        
        </div>
    );
}

export default Solutions;
