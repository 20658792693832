import styles from './Download.module.scss';
// import vanImage from '../../data/images/Vancouver-Pic.jpeg';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useEffect } from "react";
import userGuide from '../../data/images/user-guide.png';
import client from '../../data/images/client.png';
import brief from '../../data/images/brief.png';

function Download() {

    const ns = getTranslationNS('App');
    const { t } = useTranslation([ns, 'common']);

    useEffect(() => window.scrollTo(0, 0), []);

    t;

    const addLink = (fullText, linkWord, link) => {
        const splitText = fullText.split(linkWord);
        if (splitText.length < 2) {
            return (
                <>{fullText}</>
            );
        }
        return (
            <span style={{display: 'block'}}><>{splitText[0]}</><a className={styles.redir} href={link} target="_blank"  rel="noreferrer" >{linkWord}</a><>{splitText[1]}</></span>
        );
    };
    
    const downloads = [
        {
            name: 'Aupera VMSS 2.0 Framework User Guide',
            img: userGuide,
            header: 'Aupera VMSS 2.0 Framework User Guide',
            text: "Aupera VMSS is a software framework for Video AI inferencing applications. VMSS2.0 allows users to rapidly build, configure, and deploy Computer Vision pipelines using a graphical user interface (GUI); with no coding. Custom pipelines can be easily built using Aupera's node toolkit, decoding, pre-processing, post-processing, etc.; or by creating custom nodes which can be uploaded, built, tested, and used all from the GUI. The VMSS framework can be downloaded on Xilinx App Store here.",
            link: 'https://auperatechvancouver-my.sharepoint.com/:b:/g/personal/yinghua_auperatech_com/EQkPTzU2VsxCrEBIo9ZEgysBLOmGmV5WNNjCOrEIJSw_LA?e=9cdDk9',
        }, {
            name: 'Aupera Face Recognition User Guide',
            img: userGuide,
            header: 'Aupera Face Recognition User Guide',
            text: "This document describes how to setup Aupera's Face Recognition application on Xilinx Alveo U30 acceleration card, and is used in conjunction of the application listed on Xilinx App Store.",
            link: 'https://auperatechvancouver-my.sharepoint.com/:b:/g/personal/yinghua_auperatech_com/EYW6rO4cpy1CvZHvYd06NjABG-SQewB9kObN_q6WnGEc8A?e=F0BWIx',
        }, {
            name: 'Aupera Face Recognition Client Software',
            img: client,
            header: 'Aupera Face Recognition Client Software',
            text: "Used for evaluation of Aupera Face Recognition AI application. The application is listed on Xilinx App Store for customers' download and install.",
            link: 'https://auperatechvancouver-my.sharepoint.com/:b:/g/personal/yinghua_auperatech_com/EYW6rO4cpy1CvZHvYd06NjABG-SQewB9kObN_q6WnGEc8A?e=F0BWIx',
        }, {
            name: 'Aupera Face Recognition Solution Release on Xilinx Kria SOM Platform',
            img: client,
            header: 'Aupera Face Recognition Solution Release on Xilinx Kria SOM Platform',
            text: 'Aupera-kv260-fr-1.1.wic.gz file for Aupera Face Recognition Solution on SOM platform',
            link: 'https://auperatechvancouver-my.sharepoint.com/:u:/g/personal/steven_auperatechvancouver_onmicrosoft_com/Efa_9ShuqxRMoX42YuBgM5kBKyLMwN40P6SWkblMOB-tug?e=yubTWC',
        }, {
            name: 'AupV205 Smartedge AI Box Solution Brief',
            img: brief,
            header: 'AupV205 Smartedge AI Box Solution Brief',
            text: "This is a brief introduction of Aupera's AI application based on Aupera's Smartedge AI Box AupV205.",
            link: 'https://auperatechvancouver-my.sharepoint.com/personal/yinghua_auperatech_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fyinghua%5Fauperatech%5Fcom%2FDocuments%2FApp%20Store%2FAupera%5FSmartEdgebox%5FAupV205%2Dv1%2Epdf&parent=%2Fpersonal%2Fyinghua%5Fauperatech%5Fcom%2FDocuments%2FApp%20Store&originalPath=aHR0cHM6Ly9hdXBlcmF0ZWNodmFuY291dmVyLW15LnNoYXJlcG9pbnQuY29tLzpiOi9nL3BlcnNvbmFsL3lpbmdodWFfYXVwZXJhdGVjaF9jb20vRVRjWDVxRm9hOTlGZ1ZFUVJGZlRFNndCN1JUTHViWWFjWjJJa3A5clRxZTc3QT9ydGltZT1KbW5mMF9BYTJVZw',
        }, {
            name: 'Xilinx Alveo U30+U50 AI Acceleration Card with Aupera AI Solution Brief',
            img: brief,
            header: 'Xilinx Alveo U30+U50 AI Acceleration Card with Aupera AI Solution Brief',
            text: "This is a brief introduction of Aupera's AI application running on Xilinx Alveo U30+U50 AI acceleration cards.",
            link: 'https://auperatechvancouver-my.sharepoint.com/:b:/g/personal/yinghua_auperatech_com/EVYOBLRXLANFplPAlTPTv_gBpFFP3ZZYhRCc0vl1h89cKQ?e=rmsZAJ',
        }, 
    ];

    return (
        <div className={styles.component}>
        <div className={styles.article}>
            <div className={styles.titleI}>Download</div>
            <p className={styles.para}>For all our product related resources, please find the relevant files listed below to download.</p>

        </div>

            
        <article className={styles.downloads}>
            {downloads.map(dl => (
                <div key={dl.name} className={styles.downloads__card}>
                    <img className={styles.downloads__card__img} src={dl.img} alt={dl.name}/>
                    <div className={styles.downloads__card__contents}>
                        <h2 className={styles.downloads__card__header}>{dl.header}</h2>
                        <p className={styles.downloads__card__text}>{addLink(dl.text, 'Xilinx App Store', 'https://www.xilinx.com/products/acceleration-solutions/xilinx-aupera-vmss.html')}</p>
                    </div>
                    <a
                        className={styles.downloads__card__button}
                        href={dl.link}
                        target="_blank"
                        rel="noreferrer"
                    >Download</a>
                </div>
            ))}
        </article>

        </div>
    );
}

export default Download;
