import { useState } from "react";
import initialSettings from "settings.json";

export default function useSetting() {
  const locationHost = window.location.host.split(":")[0];
  // const init = {
  //   usr_mgmt: {
  //     protocol: { ...initialSettings }.user_mgmt.protocol,
  //     host: { ...initialSettings }.user_mgmt.host || host,
  //     port: { ...initialSettings }.user_mgmt.port,
  //     path: { ...initialSettings }.user_mgmt.root_path,
  //   },
  //   cfAPI: {
  //     protocol: { ...initialSettings }.client_facing_API.protocol,
  //     host: { ...initialSettings }.client_facing_API.host || host,
  //     port: { ...initialSettings }.client_facing_API.port,
  //     path: { ...initialSettings }.client_facing_API.root_path,
  //   },

  // };

  const composeURL = (keyName) => {
    const protocol = { ...initialSettings }[keyName].protocol;
    const host = { ...initialSettings }[keyName].host || locationHost;
    const port = { ...initialSettings }[keyName].port;
    const path = { ...initialSettings }[keyName].root_path;
    const fixedPath = path[0] === "/" ? path.slice(1, path.length) : path;
    return `${protocol}://${host}${port ? `:${port}` : ""}${
      path ? `/${fixedPath}` : ""
    }`;
  };

  const [settings, setSettings] = useState({
    userMgmt: composeURL("user_mgmt"),
    avac: composeURL("avac"),
  });

  const updateSettings = (newSettings) => setSettings(newSettings);

  return { settings, updateSettings };
}
