import styles from './Careers.module.scss';

import img1 from 'data/images/careers/1.jpg';
import img2 from 'data/images/careers/2.jpg';
import img3 from 'data/images/careers/3.jpg';
import img4 from 'data/images/careers/4.jpg';
import img5 from 'data/images/careers/5.jpg';
import img6 from 'data/images/careers/6.png';
import img7 from 'data/images/careers/7.png';

import vacancies from 'data/careers/vacancies.json';

import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useEffect } from 'react';
import Vacancy from './Vacancy/Vacancy';


import vanImage from '../../data/images/Vancouver-Pic.jpeg';
import szImage from '../../data/images/ShenZhen.png';

function Careers() {

    const ns = getTranslationNS('Careers');
    const { t } = useTranslation([ns, 'common']);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <div className={styles.component}>
            
            <div className={styles.titleI}>About Aupera and Our Team</div>
            <p className={styles.para}>A team of AI scientists, data engineers, software developers, silicon designers, and hardware experts have researched, designed, and built the leading intelligent video processing products from the edge to the cloud. We are in beautiful Vancouver, Canada, serving a rapidly growing customer base globally with best-in-class AI and Machine Learning technologies.</p>
            <div className={styles.team}>
                <div className={styles.teamLoc}>
                    <img className={styles.teamLocImg} src={vanImage} alt="" />
                    <p className={styles.teamLocAlt}>Vancouver, Canada</p>
                </div>
                <div className={styles.teamLoc}>
                    <img className={styles.teamLocImg} src={szImage} alt="" />
                    <p className={styles.teamLocAlt}>ShenZhen, China</p>
                </div>
            </div>

            <div className={styles.culture_header}>
                <p className={styles.culture_header__bold}>{t('cultureHeaderBold')}</p>
                <p 
                    className={`${styles.culture_header__bold} ${styles.culture_header__bold_right}` }
                    >{t('cultureHeaderText')}
                </p>
            </div>

            <p className={styles.culture_main_text}>{t('cultureMainText')}</p>

            <section className={styles.images}>
                <div className={styles.images__main_row}>
                    <div className={styles.images__col_20}>
                        <img className={styles.images__img_h30} src={img1} />
                        <img className={styles.images__img_h25} src={img2} />
                    </div>
                    <div className={styles.images__col_30}>
                        <img className={styles.images__img_h25} src={img3} />
                        <img className={styles.images__img_h30} src={img4} />
                    </div>
                    <div className={styles.images__col_20}>
                        <img className={styles.images__img_h30} src={img5} />
                        <img className={styles.images__img_h25} src={img6} />
                    </div>
                </div>
                <img className={styles.images__img7} src={img7} />
            </section>

            <div className={styles.career_header}>
                <p className={styles.career_header__bold}>{t('careerHeaderBold')}</p>
                <p className={styles.career_header__text}>{t('careerHeaderText')}</p>
            </div>

            <div className={styles.vacancies}>
                {vacancies.map(vacancy => <Vacancy key={vacancy.name} vacancy={vacancy} />)}
            </div>

        </div>
    );
}

export default Careers;
