import styles from './NavBar.module.scss';
import auperaLogo from '../../data/images/aupera-logo-color-grey-letters.png';
import auperaLogoBlack from '../../data/images/aupera-logo-color.png';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
// import * as initialSettings from 'settings.json';
import PropTypes from 'prop-types';

export default function NavBar(props) {

    const ns = getTranslationNS('App');
    const { t } = useTranslation([ns, 'common']);

    const navigate = useNavigate();
    const location = useLocation();
    // const [path, setPath] = useState('');
    const [scroll, setScroll] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(false);
    // const host = window.location.host.split(':')[0];

    // const init = {
    //     usr_mgmt: {
    //         protocol: {...initialSettings}.user_mgmt.protocol,
    //         host: {...initialSettings}.user_mgmt.host || host,
    //         port: {...initialSettings}.user_mgmt.port,
    //         path: {...initialSettings}.user_mgmt.root_path,
    //     },
    // };

    

    useEffect(() => {
        props.setPath(location.pathname.split("/")[1]);
    }, [location.pathname]);

    const changeBg = () => {
        if (window.scrollY >= 30) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    useEffect(() => {
        changeBg();
        window.addEventListener("scroll", changeBg);
    }, []);

    const onClickSidebar = (pth) => {
        navigate(pth);
        setOpenSidebar(!openSidebar);
    };

    return (
        <nav id='nav' className={`${styles.navbar} ${styles.fadein}`}>
            <div className={props.path==='' ? 
                scroll ? styles.page : styles.home : 
                styles.page
            }>
                <img
                    className={`${styles.logo} ${styles.fadein}`}
                    src={auperaLogo}
                    onClick={() => navigate('/')} 
                />
                <div className={styles.links} >
                    {/* <p 
                        className={props.path==='' ? styles.link__selected : styles.link} 
                        onClick={() => navigate('/')}>{t('common:home')}
                    </p> */}
                    <p 
                        className={props.path==='platform' ? styles.link__selected : styles.link} 
                        onClick={() => navigate('/platform')}>{t('common:platform')}
                    </p>
                    <p 
                        className={props.path==='solutions' ? styles.link__selected : styles.link} 
                        onClick={() => navigate('/solutions')}>{t('common:solutions')}
                    </p>

                    <p className={props.path==='careers' ? styles.link__selected : styles.link} onClick={() => navigate('/careers')}>{t('common:careers')}</p>
                    {/* <p className={props.path==='blog' ? styles.link__selected : styles.link} onClick={() => navigate('/blog')}>{t('common:blog')}</p> */}
                    <p className={props.path==='downloads' ? styles.link__selected : styles.link} onClick={() => navigate('/downloads')}>{t('common:downloads')}</p>


                </div>
                {/* <p 
                    className={styles.corner}
                    onClick={() => window.open('https://www.vmaccel.com/vmssdemo', '_blank').focus()}
                    >VMSS 2.0</p> */}
                <div 
                    className={styles.corner}
                    onClick={() => window.open(`${props.settingHook.settings.userMgmt}/login`, '_self').focus()}  >
                    {/* onClick={() => window.open(`${init.usr_mgmt.protocol}://${init.usr_mgmt.host}:${init.usr_mgmt.port}${init.usr_mgmt.props.path}/login`, '_self').focus()}  > */}
                    <div className={styles.button}>Sign In</div>
                </div>

                <div className={styles.corner__mobile}>
                    <div className={openSidebar ? styles.cancel : styles.burger} onClick={() => setOpenSidebar(!openSidebar)}>
                        <div className={openSidebar ? styles.line__cancel1 : styles.line}></div>
                        <div className={openSidebar ? styles.line__cancel2 : styles.line}></div>
                        <div className={openSidebar ? styles.line__cancel3 : styles.line}></div>
                    </div>
                </div>
            </div>

            <div className={`${styles.sidebar} ${openSidebar && styles.sidebar__open}`} 
            // style={openSidebar ? {display: 'flex'} : {display: 'none'}}
            >
                <img
                    className={styles.logo}
                    src={auperaLogoBlack}
                    onClick={() => onClickSidebar('/')} 
                />
                <div className={styles.links__sidebar} >
                    <div className={styles.dividerII}></div>
                    <p 
                        className={props.path==='' ? styles.link__selected : styles.link} 
                        onClick={() => onClickSidebar('/')}>{t('common:home')}
                    </p>
                    <div className={styles.dividerI}></div>
                    <p 
                        className={props.path==='platform' ? styles.link__selected : styles.link} 
                        onClick={() => onClickSidebar('/platform')}>{t('common:platform')}
                    </p>
                    <div className={styles.dividerI}></div>
                    <p 
                        className={props.path==='solutions' ? styles.link__selected : styles.link} 
                        onClick={() => onClickSidebar('/solutions')}>{t('common:solutions')}
                    </p>
                    <div className={styles.dividerI}></div>
                    <p className={props.path==='careers' ? styles.link__selected : styles.link} onClick={() => onClickSidebar('/careers')}>{t('common:careers')}</p>
                    {/* <div className={styles.dividerI}></div> */}
                    {/* <p className={props.path==='blog' ? styles.link__selected : styles.link} onClick={() => onClickSidebar('/blog')}>{t('common:blog')}</p> */}
                    <div className={styles.dividerI}></div>
                    <p className={props.path==='downloads' ? styles.link__selected : styles.link} onClick={() => onClickSidebar('/downloads')}>{t('common:downloads')}</p>
                    <div className={styles.dividerII}></div>
                {/* <p 
                    className={styles.name}
                    onClick={() => window.open('https://www.vmaccel.com/vmssdemo', '_self').focus()}
                >VMSS 2.0</p> */}

                <div className={styles.name} 
                    onClick={() => window.open(`${props.settingHook.settings.userMgmt}/login`, '_self').focus()}
                    // onClick={() => window.open(`${init.usr_mgmt.protocol}://${init.usr_mgmt.host}:${init.usr_mgmt.port}${init.usr_mgmt.path}/login`, '_self').focus()}
                >
                    <div className={styles.button}>Sign In</div>
                </div>
                </div>
            </div>
        </nav>
    );
}

// export default NavBar;

NavBar.propTypes = {
    settingHook: PropTypes.object,
    path: PropTypes.string,
    setPath: PropTypes.func,
};