import styles from './PopUp.module.scss';
// import { useTranslation } from 'react-i18next';
// import { getTranslationNS } from 'utils';
// import okIcon from 'data/images/ok.png';
// import errorIcon from 'data/images/error.png';
import PropTypes from 'prop-types';
import DialogueWindow from '../dialogue-window/DialogueWindow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faTriangleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';

export default function PopUp (props) {

    const { popupState, onClose } = props;
    const { type, message, description } = popupState;
    // const { t } = props.customHooks;
    // const ns = getTranslationNS('PopUp');
    // const { t } = useTranslation([ns, 'common']);

    const popupTypes = {
        success: {
            icon: <FontAwesomeIcon className={`${styles.icon} ${styles.icon__success}`} icon={faCircleCheck} />,
            header: 'Success',
            // header: t('success'),
        },
        error: {
            icon: <FontAwesomeIcon className={`${styles.icon} ${styles.icon__error}`} icon={faCircleExclamation} />,
            header: 'Error',
            // header: t('error'),
        },
        warning: {
            icon: <FontAwesomeIcon className={`${styles.icon} ${styles.icon__warning}`} icon={faTriangleExclamation} />,
            header: 'Warning',
            // header: t('warning'),
        },
    };
    //console.log(type, message, description);
    if (!(type && message)) return null;
    const messageToShow = (typeof message === 'string') ? message : ' ';

    return (
        <DialogueWindow
            onClose={onClose}
            headerText={popupTypes[type].header}
        >
            <div className={styles.component}>
                <div className={styles.icon_text_wrapper}>
                    {popupTypes[type] && popupTypes[type].icon}
                    <div className={styles.description_and_message}>
                        {description && <p className={styles.description}>{description}</p>}
                        {message && <p className={styles.message}>{messageToShow}</p>}
                    </div>

                </div>
                <Button
                    color='blue'
                    onClick={onClose}
                    style={{width: '6em'}}
                    className={styles.ok}
                    >{'OK'}
                </Button>
            </div>
        </DialogueWindow>
    );
}

PopUp.propTypes = {
    popupState: PropTypes.shape({
        type: PropTypes.string,
        description: PropTypes.string,
        message: PropTypes.any,
    }),
    onClose: PropTypes.func,
    customHooks: PropTypes.object,
};