import styles from './Button.module.scss';
import PropTypes from 'prop-types';

export default function Button (props) {

    const { icon, onClick, style, color, disabled, className } = props;
    

    return (
        <div 
            className={`${styles.button} ${className ? className : undefined}  ${disabled ? styles.disabled : styles[color]} `}
            style={style}
            onClick={disabled ? undefined : onClick}
        >
            {icon && <div className={styles.icon}>{icon}</div>}
            <p 
                className={`${styles.text} ${icon ? styles.text__with_icon : styles.text__no_icon}`}
            >{props.children}</p>
        </div>
    );
}

Button.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    icon: PropTypes.any,    
    style: PropTypes.object,
    color: PropTypes.string,
    disabled: PropTypes.any,
    className: PropTypes.any,
};