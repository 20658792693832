import './App.css';
import styles from './App.module.scss';
import './i18n';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import { useTranslation } from 'react-i18next';
// import { getTranslationNS } from 'utils';

import NavBar from 'components/NavBar/NavBar';
import Home from 'components/Home/Home';
import Platform from 'components/Platform/Platform';
import Solutions from 'components/Solutions/Solutions';
import SmartRetail from 'components/SmartRetail/SmartRetail';
import SmartBuilding from 'components/SmartBuilding/SmartBuilding';
import WorkSafety from 'components/WorkSafety/WorkSafety';
import SmartParking from 'components/SmartParking/SmartParking';
import Download from 'components/Download/Download';
import vanImage from 'data/images/city.jpg';
import Careers from 'components/Careers/Careers';
import Footer from 'components/Footer/Footer';
import { useEffect, useState } from 'react';
import PopUp from 'components/basic-components/popup/PopUp';
import axios from 'axios';

import useSetting from 'hooks/useSetting';
import awintro from 'data/videos/awintro.mp4';


// import 'overlayscrollbars/overlayscrollbars.css';
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

// import Blog from 'components/Blog/Blog';
// import ArticlePage from 'components/Blog/ArticlePage/ArticlePage';

function App() {
    // const ns = getTranslationNS('App');
    // const { t } = useTranslation([ns, 'common']);
    const settingHook = useSetting();
    const [path, setPath] = useState('');

    const [popupState, setPopupState] = useState({
        type: null,
        description: null,
        message: null,
    });
    const [videoEnded, setVideoEnded] = useState(false);

    const closePopup = () =>
        setPopupState({
            type: null,
            description: null,
            message: null,
        });

    useEffect(() => {
        const params = new URL(document.location).searchParams;
        const noverify = params.get('noverify');
        //console.log(noverify);
        if (noverify) {
            axios.get(`${settingHook.userMgmt}/logout`, {headers: {'Cache-Control': 'no-cache'}})
                .then(res => console.log(res))
                .catch(err => console.log(err));

            setPopupState({
                type: 'warning',
                description: `Email "${noverify}" is not verified.`, 
                message: `If you have just created a new account, check the inbox of ${noverify} for a confirmation mail. After you verify your email, try to sign in again.`,
            });
        }
    }, []);


    return (
        <div className={styles.pageWrapper} id='pageWrapper' >
            {
                !videoEnded && (
                    <video
                        autoPlay
                        muted
                        playsInline
                        onEnded={() => setVideoEnded(true)}
                        className={styles.introVideo}
                    >  
                        <source src={awintro} type="video/mp4" />
                        your browser does not support the video tag
                    </video>
                ) 
            }
            {   
                <Router >
                    <main className={styles.app}>
                        {videoEnded && <NavBar settingHook={settingHook} path={path} setPath={setPath} />}

                        <Routes>
                            <Route path='/' element={<Home videoEnded={videoEnded} />} />
                            <Route path='/platform' element={<Platform />} />
                            <Route path='/solutions' element={<Solutions />} />
                            <Route path='/careers' element={<Careers />} />

                            {/* <Route path='/blog' element={<Blog />} /> */}
                            {/* <Route path='/blog/:id' element={<ArticlePage />} /> */}

                            <Route path='/downloads' element={<Download />} />
                            <Route path='/solutions/smart-retail' element={<SmartRetail />} />
                            <Route path='/solutions/smart-building' element={<SmartBuilding />} />
                            <Route path='/solutions/smart-parking' element={<SmartParking />} />
                            <Route path='/solutions/work-safety' element={<WorkSafety />} />

                            <Route
                                path='*'
                                element={
                                    <section className={styles.not_found}>
                                        <h1>404</h1>
                                        <p>Page Not Found</p>
                                    </section>
                                }
                            />
                        </Routes>

                        {/* {videoEnded && <Footer />} */}
                        {
                            videoEnded && <>
                                <Footer />
                                {path!=="" ? <div className={styles.bg} id='bg'></div> : <div className={styles.bgblack} id='bg'></div>}
                                <img className={styles.vanBg} src={vanImage} alt='Vancouver, Canada' />
                            </>
                        }
                    </main>
                </Router>
            }
            {popupState.message && 
                <PopUp 
                    popupState={popupState} 
                    onClose={() => closePopup()} 
                />
            }
        </div>
            
    );
}

export default App;
