import styles from './Home.module.scss';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useNavigate, useLocation } from 'react-router-dom';
import awintroAfter from 'data/videos/awintro_after.mp4';
import { useEffect, useState, useRef } from 'react';

import model_data_bg from 'data/images/whatweprovide/bg.png';
import model_data_labeledtracklet from 'data/images/whatweprovide/labeledtracklet.png';
import model_data_notlabeledresult from 'data/images/whatweprovide/notlabeledresult.png';
import model_data_labeledresult from 'data/images/whatweprovide/labeledresult.png';
import PropTypes from 'prop-types';

// import replacer from 'data/images/avac.png';

import vmaccel from 'data/images/partners/vmaccel.png';
import amd from 'data/images/partners/amd.png';
import tencent from 'data/images/partners/tencent.png';
import excelpoint from 'data/images/partners/excelpoint.png';


import parkingImg from 'data/images/smart-parking.png';
import safetyImg from 'data/images/work-safety.jpg';
import retailImg from 'data/images/smart-retail.png';


// import cloud from 'data/images/whatweprovide/cloud.png';
// import device from 'data/images/whatweprovide/device.png';
// import lock from 'data/images/whatweprovide/lock.jpg';
import '../../styles/form.scss';
import './tilted.css';



import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Flex,
  Progress,
} from 'antd';
import countries from '../../data/countries';

const SlideUpOnScroll = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(entry.isIntersecting);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className={isVisible ? `${styles.slideup} ${styles.visible}` : styles.slideup} >
      {children}
    </div>
  );
};


function Home(props) {
    const ns = getTranslationNS('Home');
    const { t } = useTranslation([ns, 'common']);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [res, setRes] = useState("inactive");
    const [percent, setPercent] = useState(0);
    
    useEffect(() => {
        if (!location.hash) return;
        window.location = location.hash;
    }, [location]);

    
    const onSubmit = async (values) => {
      // e.preventDefault();
      setRes("submitting");
      setPercent(0);
      const formData = new FormData();
      for (const key in values) {
          formData.append(key, values[key]);
      }

      formData.append("access_key", process.env.REACT_APP_FORMAPI);

      const result = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
      }).then((res) => res.json());
      if (result.success) {
          console.log("Success", result);
          setRes("success");
      } else {
          console.log("Error", result);
          setRes("exception");
      }
    };

    useEffect(() => {
        if (res === "inactive") return;
        const interval = setInterval(() => {
            if (percent < 100) {
                setPercent(prev => prev + 1);
            } 
        }, 5);

        return () => clearInterval(interval);

    }, [res, percent]);

    const closePopup = () => {
        setRes("inactive");
        setPercent(0);
    };

    
    useEffect(() => window.scrollTo(0, 0), []);

    const solutions = [
      { name: 'parking', img: parkingImg, header: t('common:smartParking'), text: t('parkingDesc'), link: '/solutions/smart-parking' },
      { name: 'safety', img: safetyImg, header: t('common:workSafety'), text: t('safetyDesc'), link: '/solutions/work-safety' },
      { name: 'retail', img: retailImg, header: t('common:smartRetail'), text: t('retailDesc'), link: '/solutions/smart-retail' },
    ];  

    const sectionTwoInfo = [
      {
        title: 'AI Inference',
        desc: 'Our algorithm fully exploits the capability of FPGA acceleration, delivering cutting-edge inference technology that produces industry-leading, accurate results with high-speed performance.',
        img: 'hardware image',
        style: 'floating hardware animation',
        comp: <div className={styles.device} >
          {/* <img className={styles.device__bg} src={device} alt="" /> */}
          <div className="box">
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="box-contents-one"></div>
          </div>
        </div>
      }, {
        title: 'Model Retraining and Data Labeling',
        desc: 'Our Video Machine Learning Streaming Server enables businesses to develop and deploy sub-frame real-time computer vision applications, retrain models with personalized private data, and monitor results from configurable video analytics.',
        img: 'LPR card on top of faded output image of the car',
        style: 'flowing MLOps LP labeled card, on hover shows result card, excluded half transparent',
        comp: <div className={styles.modeldata} >
          <img className={`${styles.modeldata__bg} ${styles.modeldata__bg_fadein}`} src={model_data_bg} alt="" />
          <img className={styles.modeldata__labeledtracklet} src={model_data_labeledtracklet} alt="" />
          <img className={styles.modeldata__labeledresult} src={model_data_labeledresult} alt="" />
          <img className={styles.modeldata__nolabeledresult} src={model_data_notlabeledresult} alt="" />
        </div>
      }, {
        title: 'Cloud Dashboard',
        desc: 'User-friendly online platform that integrates all of our technologies and applications into one cohesive experience. Without any coding needed, developers can unlock all of our Video AI applications with unprecedented efficiency. Experience simplicity and limitless possibilities, all at your fingertips.',
        img: '3-5 screenshot of platform maybe photoshopped either rotating every few seconds, or tower effect',
        style: 'rotating or floating',
        comp: <div className={styles.cloud} >
          {/* <img className={styles.cloud__bg} src={cloud} alt="" /> */}
          <div className="box">
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="box-contents-three"></div>
          </div>
        </div>
      }, {
        title: 'Privacy',
        desc: "Aupera has a rich history in video technologies, evolving from video processing to AI analytics. Our groundbreaking innovation empowers customers to retrain and refine their AI models, ensuring accurate results without the need to share their confidential data. The future of AI relies on data. In contrast to ChatGPT, which is accessible to everyone, industry data cannot be freely shared. Aupera's solution enables industries to conduct video AI analytics without the needed to share any data, transforming ephemeral data into crucial training data.",
        img: 'lock',
        style: 'lock',
        comp: <div className={styles.privacy} >
          {/* <img className={styles.privacy__bg} src={lock} alt="" /> */}
          <div className="box">
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="hover-point"></div>
            <div className="box-contents-four"></div>
          </div>
        </div>
      }
    ];

    return (
      <div id='home' className={styles.home} style={props.videoEnded ? {display: "flex"} : {display: "none"}}>
        <div className={styles.section_one}>
          <video
              className={styles.video}
              autoPlay
              muted
              loop
              playsInline
              src={awintroAfter}>
          </video>
          <div className={styles.section_one__text_wrap}>
              <h1 className={styles.section_one__text_wrap__header}>{t('homeHeader')}</h1>
              <p className={styles.section_one__text_wrap__desc}>{t('platformDescription')}</p>
              {/* <Button type='primary' >Request Device</Button> */}
              <a href="#request-device">
                <div className={styles.section_one__text_wrap__button} >Request Device</div>
              </a>
            <a href="#what-we-provide">
              <div className={styles.section_one__text_wrap__scrolldown} id="what-we-provide" href="#what-we-provide"></div>
            </a>
          </div>
        </div>
        <div className={styles.section_two} >
        <SlideUpOnScroll>
          <div className={styles.section_two__contentWrapper}>
            <div className={styles.section_two__title}>What we provide - VMSS, Video AI Software Platform</div>
            <div className={styles.section_two__desc}>A cloud platform offering zero coding capabilities and providing AI inference, custom computer vision pipeline development and deployment, data collection, and model re-training all in one, unlocking video AI for all developers.</div>
            <div className={styles.section_two__menu}>
              <div className={styles.section_two__demo}>
                {sectionTwoInfo[selectedTab].comp}
              </div>
              <div className={styles.section_two__info}>
                {
                  sectionTwoInfo.map((info, i) => {
                    return (
                      <div className={styles.section_two__info__item} key={i} >
                        <div className={styles.section_two__info__item__header} onClick={() => setSelectedTab(i)}>
                          <div className={styles.section_two__info__item__title} >{info.title}</div>
                          <a className={`${styles.arrowIcon} ${i===selectedTab && styles.open}`}>
                            <span className={styles.leftBar}></span>
                            <span className={styles.rightBar}></span>
                          </a>
                        </div>
                        <div className={`${styles.section_two__info__item__desc} ${i!==selectedTab && styles.section_two__info__item__desc__hidden}`}>{info.desc}</div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </SlideUpOnScroll>
        </div>
        
        <div className={styles.section_three} >
          <SlideUpOnScroll>
          <div className={styles.section_three__title}>Strategic Partners</div>

          <div className={styles.section_three__partnerslogos}>
            <img className={styles.section_three__partnerslogos__vmaccel} src={vmaccel} alt="" />
            <img className={styles.section_three__partnerslogos__amd} src={amd} alt="" />
            <img className={styles.section_three__partnerslogos__tencent} src={tencent} alt="" />
            <img className={styles.section_three__partnerslogos__excelpoint} src={excelpoint} alt="" />
          </div>
          
          <div className={styles.section_three__desc}>
            <div className={styles.section_three__desc__text}>
              At Aupera, we believe in the power of collaboration to shape a brighter future. Our journey is defined by our steadfast commitment to innovation and the pursuit of shared goals. We are delighted to be in partnership with some of the most forward-thinking companies, forming meaningful alliances based on a common vision for the future.
            </div>
            <a className={styles.section_three__desc__link} href="https://vmaccel.com/cotm/company-of-the-month-october/">
              Read more about Aupera from our partner - VMAccel
            </a>
          </div>
          
          </SlideUpOnScroll>
        </div>
        <div className={styles.section_four} >
          
          <SlideUpOnScroll>
          <div className={styles.section_four__title}>Industry Solutions</div>
          <div className={styles.section_four__cards}>
            {solutions.map(sol => (
              <div key={sol.name} className={styles.section_four__card}>
                <img className={styles.section_four__card__img} src={sol.img} alt={sol.name} />
                <h2 className={styles.section_four__card__header}>{sol.header}</h2>
                <p className={styles.section_four__card__text}>{sol.text}</p>
                <p
                    className={styles.section_four__card__button}
                    onClick={() => navigate(sol.link)}>
                    {t('common:findOutMore')}
                </p>
              </div>
            ))}
          </div>
          </SlideUpOnScroll>
        </div>
        <div className={styles.section_five}>
        <SlideUpOnScroll>
        <div className={styles.form} id="request-device">
                <h1 className={styles.form__title}>
                    {t('formTitle')}
                </h1>
                <p className={styles.form__desc}>{t('formDescription')}</p>

                <Form
                    variant="filled"
                    style={{
                        maxWidth: 800,
                    }}
                    onFinish={onSubmit}

                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your name.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Please enter a valid email.'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="Country" 
                        name="country" 
                        rules={[{
                            required: true,
                            message: 'Please enter where you are.'
                        }]}
                    >
                        <Select showSearch >
                            {
                                countries.map((country) => (
                                    <Select.Option key={country} value={country}>{country}</Select.Option> 
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Company/University"
                        name="Company/University"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Job Title"
                        name="Job Title"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        name="Want to know more about custom solutions"
                        valuePropName="checked"
                        label=" "
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        colon={false}
                    >
                        <Checkbox >Do you want to know more about custom solutions from Aupera and its partners?</Checkbox>
                    </Form.Item>
                    <Form.Item 
                        label="Hardware interested" 
                        name="Hardware interested" 
                        rules={[{
                            required: true,
                            message: 'Please choose a hardware you want to request.'
                        }]}
                    >
                        <Select >
                            <Select.Option value="V70">V70</Select.Option>
                            <Select.Option value="Aupera-V205">Aupera V205</Select.Option>
                            <Select.Option value="PlanetSpark-X7">PlanetSpark X7</Select.Option>
                            <Select.Option value="Kria-SOM-K260">Kria SOM K260</Select.Option>
                            <Select.Option value="U50">U50</Select.Option>
                            <Select.Option value="U30">U30</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        label="How did you hear about us?" 
                        name="Hear from" 
                        rules={[{
                            required: true,
                            message: 'Please choose where you heard about us.'
                        }]}
                    >
                        <Select >
                            <Select.Option value="amd-xilinx">AMD/Xilinx</Select.Option>
                            <Select.Option value="VMAccel">VMAccel</Select.Option>
                            <Select.Option value="LinkedIn">LinkedIn</Select.Option>
                            <Select.Option value="Partner">Partner</Select.Option>
                            <Select.Option value="Other">Other</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        name="Allow share information"
                        valuePropName="checked"
                        label=" "
                        rules={[
                          {
                            required: true,
                            validator: (_, value) =>
                              value ? Promise.resolve() : Promise.reject(new Error('This field is required')),
                          },
                        ]}
                        colon={false}
                    >
                        <Checkbox >I allow Aupera to share my information with its partners.</Checkbox>
                    </Form.Item>
                    <Form.Item id="submit" >
                        <Button type="primary" htmlType="submit" loading={res !== "inactive" ? true : false}>
                            {res !== "inactive" ? "Sending..." : "Submit"}
                        </Button>
                    </Form.Item>
                </Form>
                {
                    res !== "inactive" &&
                        <div className={styles.response}>
                            <Flex gap="small" wrap="wrap">
                                <Progress type="circle" percent={percent} format={percent<100 ? () => "Sending..." : null} status={percent === 100 ? res : null}/>
                            </Flex>
                            {
                                percent < 100 && <p>Submitting Request</p>
                            }
                            {
                                res === "success" && percent >= 100 && <>
                                    <p>Your request has been submitted,</p>
                                    <p>Our representative will contact you soon.</p>
                                    {percent >= 100 && <a onClick={() => closePopup()} className={styles.urlTag} >Go Back</a>}
                                </>
                            }
                            {
                                res === "exception" && percent >= 100 && <>
                                    <p>Something went wrong,</p>
                                    <p>Please try again later, or email us at &nbsp;<a href='mailto: request@auperatech.com' className={styles.urlTag} >request@auperatech.com</a></p>
                                    {percent >= 100 && <a onClick={() => closePopup()} className={styles.urlTag} >Go Back</a>}
                                </>
                            }
                        </div>
                }
                
            </div>
            </SlideUpOnScroll>
        </div>
      </div>
    );
}

export default Home;

Home.propTypes = {
    videoEnded: PropTypes.bool,
};

SlideUpOnScroll.propTypes = {
  children: PropTypes.any,
};