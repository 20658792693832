import styles from './Vacancy.module.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';

import tri from 'data/images/tri.png';

// import { useEffect } from 'react';

function Vacancy(props) {

    const [expand, setExpand] = useState(false);

    // useEffect(() => window.scrollTo(0, 0), []);
    const { vacancy } = props;

    const vacClicked = (e) => {
        setExpand(prev => !prev);
        if (expand) return;
        // window.scrollTo(0, e.target.offsetTop - 100);
        window.scrollTo({ top: e.target.offsetTop - 100, behavior: 'smooth' });
        //e.target.scrollIntoView(true);
        // e.target.scrollIntoView(true, {behavior: 'smooth'});
        console.log(e.target.offsetTop);
    };

    return (
        <div className={styles.component}>
            <div 
                className={styles.name_wrap}
                onClick={vacClicked}
                >

            <img className={`${styles.name__tri} ${expand && styles.deg180}`} src={tri} />
            <p 
                className={styles.name}
                >{vacancy.name}
            </p>
                </div>

            {expand && <div className={styles.fields}>
                {Object.keys(vacancy).map(field => {
                    if (field === 'name') return null;
                    return (<article key={field} className={styles.field}>
                        <p className={styles.field__subheader}>
                            
                            {field}
                        </p>

                        {Array.isArray(vacancy[field]) ?
                            <ul className={styles.field__list}>
                                {vacancy[field].map((el, i) =>
                                    (<li key={`${field}-${i}`}>{el}</li>)
                                )}
                            </ul>
                            :
                            <p className={styles.field__description}>{vacancy[field]}</p>
                        }
                    </article>
                    );
                })}
            </div>}
        </div>
    );
}

export default Vacancy;

Vacancy.propTypes = {
    vacancy: PropTypes.object,
};
