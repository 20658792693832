import styles from './SmartRetail.module.scss';
// import vanImage from '../../data/images/Vancouver-Pic.jpeg';
import retail from '../../data/images/retail.jpg';
import { useTranslation } from 'react-i18next';
import { getTranslationNS } from 'utils';
import { useEffect } from 'react';

function SmartRetail() {

    const ns = getTranslationNS('App');
    const { t } = useTranslation([ns, 'common']);

    useEffect(() => window.scrollTo(0, 0), []);
    t;

    return (
        <div className={styles.component}>
            <div className={styles.article}>
                <div className={styles.titleI}>Smart Retail</div>
                <div className={styles.contentSec}>
                    <img className={styles.img} src={retail} alt="" />
                    <div className={styles.content}>
                        <p className={styles.para}>With Aupera, you can accelerate in-store or data center real-time smart retail AI applications, including object classifications, customer demographics, inventory & shelf management, loss prevention and more. </p>
                        <p className={styles.para}>Aupera’s end-to-end video solution for Smart Retail application features ultra low E2E latency (sub-100ms). This offers highly parallel computing capacity in terms of streams and neural networks, enabling real-time alerts for retail applications such as loss prevention, consumer demographics and target marketing, as well as inventory and shelf management.</p>
                    </div>
                </div>
   
                <div className={styles.titleII}>Available AI functions includes:</div>
                <ul className={styles.paraBottom}>
                    <li>Object classification for loss prevention and shelf management. Free evaluation license is available here on <a className={styles.redir} href="https://www.xilinx.com/products/acceleration-solutions/xilinx-aupera-vmss.html" target="_blank"  rel="noreferrer">Xilinx App Store</a>. </li>
                    <li>Face attributes for customer demographics and target marketing. </li>
                    <li>Mask detection and Crowd statistics for public health management </li>
                    <li>Anomaly detection for oversized objects monitoring. </li>
                    <li>Face emotion detection for consumers satisfaction index.</li>
                </ul>

            </div>

        </div>
    );
}

export default SmartRetail;
