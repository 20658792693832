import styles from './Footer.module.scss';
import linkedInLogo from 'data/images/linkedin.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, /*faCircleInfo*/ } from '@fortawesome/free-solid-svg-icons';
import buildInfo from 'data/build.json';

function Footer() {

    return (
        <div className={styles.footer}>
            <p className={styles.company_name}>
                <span className={styles.build_info} title={buildInfo.buildNumber}>©</span>
                 2024 Aupera Technologies
            </p>

            <div className={styles.contact_us}>
                <p className={styles.contact_us__text}>Contact us</p>
                <img 
                    className={styles.contact_us__linkedin}
                    onClick={() => 
                        window.open('https://www.linkedin.com/company/aupera-technologies-inc-', '_blank')
                        .focus()}
                    src={linkedInLogo} 
                    alt='linkedIn'
                />
                
                <a  href='mailto:info@auperatech.com'>
                    <FontAwesomeIcon 
                        className={styles.contact_us__mail}
                        icon={faEnvelope} 
                    />
                </a>
            </div>
            {/* <p className={styles.build_info}>{buildInfo.buildNumber}</p> */}
            {/* <FontAwesomeIcon 
                        className={styles.build_info}
                        icon={faCircleInfo}
                        title={buildInfo.buildNumber}
            /> */}

        </div>
    );
}

export default Footer;
